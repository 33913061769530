.container-div {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  min-height: -webkit-fill-available;
}

.container-div-component {
  width: 96%;
  padding-top: 16px;
}

.container-div-component-content {
  width: 96%;
  padding-top: 16px;
}
