.services-select {
  width: 250px;
  font-size: 13px;
}

.services-select .ant-select-selection--single {
  justify-content: left;
  align-items: center;
  display: flex;
}

.services-select .ant-select-selection__rendered {
  justify-content: center;
  align-items: center;
  display: flex;
}