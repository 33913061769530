/* .card-component {
  border: 1px solid #e8e8e8;
} */

.card-component .ant-card-head {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}

.card-component .ant-card-body {
  font-size: 13px;
}
