.new-input-add-user-name {
  border: none;
  height: 25px;
  align-self: center;
  min-width: 100px;
  max-width: max-content;
}

.select-hidden-border .ant-select-selection {
  border: 1px solid #fff;
}

.select-hidden-border .ant-select-selection__placeholder {
  margin-left: -10px;
}

.select-hidden-border .ant-select-selection-selected-value {
  margin-left: -10px;
}

.badge-text .ant-badge-status-dot {
  width: 4px;
  height: 4px;
  margin: 0px 4px;
}

.badge-text .ant-badge-status-text {
  margin-left: unset;
}

.badge-text {
  margin: 0px 6px 0px 6px;
}
