.history-asset-image {
  padding-right: 14px;
}

.history-asset-display {
  display: inline-block;
}

.history-asset-icon {
  font-size: 16px;
}

.history-asset-list {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.history-asset-container {
  margin: 0px 24px 0px 24px;
}

.type-log-color {
  font-size: 12px;
  color: #1d3557;
  padding-right: 4px;
}
.type-log-normal {
  font-size: 12px;
  color: #1d3557;
  padding-right: 4px;
}
.type-log-bold {
  font-size: 12px;
  font-weight: bold;
  color: #1d3557;
  padding-right: 4px;
}

.hisory-divider.ant-divider-horizontal {
  margin: 14px 0;
}

.history-asset-container-all {
  overflow-y: auto;
  height: 440px;
}
