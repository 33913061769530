.action-report-menu {
  width: 200px;
}

.action-report-div-left {
  justify-content: flex-start;
  display: flex;
}

.action-report-div-right {
  justify-content: flex-end;
  display: flex;
}

.action-report-input {
  width: 210px;
  border-radius: 2px;
}

.action-report-input-7 {
  border-radius: 2px;
}

.action-report-select-column {
  width: 150px;
  font-size: 14px;
}

.action-report-rangePicker .ant-input {
  border: unset;
  color: #1d3557;
  font-size: 14px;
  width: 300px;

  /* color: #1D3557; */
}

.action-menu-item {
  background-color: #ffffff;
}

.action-menu-checkbox-span {
  font-size: 14px;
}

.action-menu-item-text-error {
  color: #ff1c00;
  text-align: left;
  background-color: #ffffff;
}

.action-button-group {
  display: flex;
  justify-content: center;
}

.action-button-group-button {
  width: 50%;
  font-size: 13px;
  padding: 0px 2px;
}

/* .action-report-select-team {
  max-width: 360px;
  min-width: 250px;
  margin-left: 10px;
  display: inline-block;
}

.action-report-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1D3557;
  border: 1px solid #1D3557;
}

.action-report-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1D3557;
} */

.action-report-tree-select-team {
  max-width: 460px;
  min-width: 300px;
  margin-left: 10px;
  display: inline-block;
}

.action-report-tree-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.action-report-tree-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}

.action-report-tree-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.action-report-tree-select-team-modal {
  max-width: 460px;
  min-width: 200px;
  margin-left: 10px;
  display: inline-block;
}

.action-report-tree-select-team-modal .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.action-report-tree-select-team-modal .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}

.action-report-tree-select-team-modal .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.action-report-dropdown-color .ant-btn {
  background-color: #1d3557;
  border-color: white;
  color: white;
}

.action-report-dropdown-button.ant-btn-group > .ant-btn:first-child:not(:last-child) {
  width: 100px;
  background: #1d3557;
  border: 1px solid #1d3557;
  color: #fff;
  margin: 0 2px;
}

.action-report-dropdown-button.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child) {
  background: #1d3557;
  border: 1px solid #1d3557;
  color: #fff;
}

/* .action-report-menu-status.ant-dropdown-menu {
  width: 130px;
} */

.action-report-dropdown-overlay {
  width: 150px !important;
}

.action-report-dropdown-overlay-user {
  width: 120px !important;
}

.layout-summary {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 36px 0px;
}

.layout-summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layout-summary-item-text {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
}

.layout-summary-item-value {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: center;
  padding-bottom: 6px;
}
