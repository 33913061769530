.comments-content-card .ant-card-head {
  padding: 0px 16px;
  width: 330px;
}

/* .comments-content-card .ant-card-body {
  padding: 16px;
  height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
} */

.comments-content {
  display: flex;
}

.comments-content-span {
  display: grid;
}

.comments-content-avatar {
  margin-right: 15px;
}
