.customer-card {
  /* min-height: calc(100vh - 112px); */
  height: calc(100vh - 112px);
}

.customer-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}

.customer-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.customer-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.customer-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.customer-modal-confirm .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.customer-modal-confirm .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}

.customer-table .ant-pagination-total-text {
  margin-right: -16px;
}

/* .customer-table .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
  height: calc(100vh - 372px);
}

.customer-table .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
  height: calc(100vh - 372px);
} */

/* .customer-table .ant-table-body {
  height: calc(100vh - 372px);
}

.customer-table .ant-table-body-outer {
  height: calc(100vh - 372px);
} */

.customer-modal-confirm .ant-modal-confirm-body {
  text-align: center;
}

.customer-modal-confirm .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.customer-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.customer-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.customer-modal-confirm .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.customer-modal-confirm .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}
