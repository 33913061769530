.preview-picture-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
}

.preview-picture-imgage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.preview-picture-column-01 {
  -ms-flex: 100%; /* IE10 */
  flex: 100%;
  max-width: 100%;
  padding: 0 1px;
  height: 415px;
}

.preview-picture-column-02 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 1px;
  height: 215px;
}

.preview-picture-column-03-01 {
  -ms-flex: 100%; /* IE10 */
  flex: 100%;
  max-width: 100%;
  padding: 0 1px 2px;
  height: 215px;
}

.preview-picture-column-03-xx {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 1px;
  height: 215px;
}

.preview-picture-column-04 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 1px 2px;
  height: 215px;
}

.preview-picture-column-04-01 {
  -ms-flex: 100%; /* IE10 */
  flex: 100%;
  max-width: 100%;
  padding: 0 1px 2px;
  height: 215px;
}

.preview-picture-column-04-02 {
  -ms-flex: 33.33%; /* IE10 */
  flex: 33.33%;
  max-width: 33.33%;
  padding: 0 1px 2px;
  height: 215px;
}

.preview-picture-column-05-01 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 1px 2px;
  height: 215px;
}

.preview-picture-column-05-02 {
  -ms-flex: 33.33%; /* IE10 */
  flex: 33.33%;
  max-width: 33.33%;
  padding: 0 1px 2px;
  height: 215px;
}

.preview-picture-column-05-plus {
  -ms-flex: 33.33%; /* IE10 */
  flex: 33.33%;
  max-width: 33.33%;
  padding: 0 1px 2px;
  height: 215px;
  position: relative;
  text-align: center;
  color: white;
}

.preview-picture-column-05-plus-box {
  background-color: #000;
  opacity: 1;
  height: 213px;
  cursor: pointer;
}

.preview-picture-column-05-plus-box:hover {
  opacity: 0.6;
}

.preview-picture-imgage-05-plus {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.6;
}

.preview-picture-centered-05-plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 39px;
}

.preview-picture-single-column {
  -ms-flex: 100%; /* IE10 */
  flex: 100%;
  max-width: 100%;
  height: 300px;
  position: relative;
  text-align: center;
  color: white;
}

.preview-picture-single-imgage {
  height: 100%;
  /* object-fit: cover; 
  object-position: center; */
}

.preview-picture-single-top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  background-color: rgba(122, 122, 122, 0.35);
  width: 34px;
  height: 34px;
  padding: 6px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.preview-picture-single-top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  background-color: rgba(122, 122, 122, 0.35);
  width: 34px;
  height: 34px;
  padding: 6px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.preview-picture-single-comment {
  margin: 18px 0px 30px 0px;
  padding: 0px 18px;
}

.preview-picture-comment-text {
  font-size: 13px;
  color: #1d3557;
}

.preview-picture-single-input {
  border: unset;
  resize: unset;
}
