.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
  text-align: center;
}

.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 4px 11px;
  width: 100px;
  text-align: center;
  margin: 0 auto;
}

.input-width {
  width: 100px;
  height: 25px;
}

.select-type {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  text-align: center;
  color: #1d3557;
  cursor: pointer;
}

.not-select-type {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.15;
  letter-spacing: normal;
  text-align: left;
  color: #bcc5d3;
  cursor: pointer;
}

.validate-error .ant-form-item-explain .ant-form-item-explain-error {
  color: blue !important;
}
