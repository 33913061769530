.organizationTree-card {
  height: calc(100vh - 112px);
  /* height: 100%; */
}

.organizationTree-card .ant-card {
  /* height: calc(100vh - 112px); */
  height: 100%;
  /* min-width: auto; */
}

.organizationTree-card .ant-card-extra {
  padding: 0px;
}

.organizationTree-card .ant-card-head {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
  padding: 0px 0px 0px 24px;
}

.organizationTree-card .ant-card-body {
  padding: 0px;
  font-size: 13px;
}

.organizationTree-input {
  padding: 24px 24px 10px 24px;
}

.organizationTree-input .ant-input {
  font-size: 13px;
}

.organizationTree-input .ant-input-prefix {
  left: 34px;
  top: 61%;
}

.organizationTree-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.organizationTree-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.organizationTree-modal-confirm .ant-btn-danger {
  color: #1d3557;
  background-color: #ffffff;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.organizationTree-modal-confirm .ant-btn-danger:hover {
  color: #ffffff;
  background-color: #1d3557;
  border-radius: 2px;
  border: 1px solid #1d3557;
}

.hoverDirectoryTree {
  padding: 0px 24px;
  /* height: calc(100vh - 112px); */
  /* overflow-y: auto; */
  /* overflow-x: hidden; */
}

.hoverDirectoryTree .ant-tree-node-content-wrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 80%;
}

.hoverDirectoryTree .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #1d3557;
}

.hoverDirectoryTree .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #e0e7f5;
}

.tree-title-p {
  font-size: 18px;
  color: #000;
}

.styleTreeNode {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.organization-manu-input-div {
  padding: 24px 24px 10px 24px;
}

.organization-manu-Tree {
  padding: 0px 24px;
  overflow: auto;
  height: calc(100vh - 262px);
}

.organization-manu-Tree .ant-tree-node-content-wrapper {
  white-space: nowrap;
  /* overflow: hidden;
  text-overflow: ellipsis;
  width: 80%; */
}

.organization-manu-Tree .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected,
.ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #1d3557;
}

.organization-manu-Tree .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
  background: #e0e7f5;
}

.organizationTree-modal-confirm .ant-btn-primary {
  border-radius: 8px !important;
  color: #fff !important;
  background-color: #6490cf !important;
  border-color: #6490cf !important;
}

.organizationTree-modal-confirm .ant-btn-primary:hover {
  border-radius: 8px !important;
  color: #6490cf !important;
  background-color: #fff !important;
  border-color: #6490cf !important;
}

.organizationTree-modal-confirm .ant-btn-danger {
  border-radius: 8px !important;
  color: #6490cf !important;
  background-color: #ffff !important;
  border-color: #6490cf !important;
}

.organizationTree-modal-confirm .ant-btn-danger:hover {
  border-radius: 8px !important;
  color: #ffff !important;
  background-color: #6490cf !important;
  border-color: #6490cf !important;
}
.ant-pagination-item-active a {
  color: #1d3557 !important;
}

.ant-btn-icon-only.ant-btn-sm:enabled {
  background-color: #6490cf;
  border-color: #6490cf;
}

.ant-checkbox:indeterminate {
  color: #6490cf !important;
  border-color: #6490cf !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
  border-color: #6490cf !important;
}
