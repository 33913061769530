.validation-css {
  color: #f5222d;
}

.shift-input {
  margin-bottom: 15px;
  width: 50%;
}

.shift-input .ant-input {
  border-right-style: hidden;
}

.shift-button {
  float: right;
  font-size: 13px;
}

.total-items-member {
  display: flex;
  position: relative;
  margin-top: -45px;
  margin-left: 10px;
}

.shift-button-modal {
  font-size: 13px;
  width: 56px;
  float: right;
  background-color: #ffffff;
}

.shift-button-modal-delete {
  text-align: center;
  font-size: 13px;
  width: 56px;
  float: right;
  background-color: #ffffff;
  padding: 0px 5px 0px 5px;
}

.shift-select-modal .ant-select-arrow-icon {
  display: none;
}

.button-width {
  width: 56px;
}

.avatar-selected {
  background-color: transparent !important;
}

.button-width-item {
  width: auto;
  /* padding: 0 0px !important; */
  /* border-style: inherit;  */
}

.button-width .anticon-plus {
  font-size: 11px;
}

.buton-circle {
  margin-left: 12px;
}

.buton-circle .anticon-plus {
  font-size: 11px;
}

.div-modal {
  margin: 4px;
}

.div-modal-big {
  display: inline-block;
  width: 275px;
  padding: 10px;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 8px;
}

.div-modal-big-icon {
  display: inline-block;
  width: 150px;
  padding: 10px;
  margin-top: 8px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 8px;
}

.div-modal-line-bottom .ant-popover-title {
  border-bottom: none;
}

.popover-arrow .ant-popover-arrow .ant-popover-content .ant-popover-placement-bottomLeft {
  border-style: none;
  width: 0px;
}

.popover-inner-content {
  border-style: none;
}

.space-bottom .ant-form-item .ant-row .ant-modal-root {
  margin-bottom: none;
}

.ant-form-item-label > label.ant-form-item-required::before {
  content: none;
}

.rules {
  margin-bottom: 8px !important;
}

.formItem-timespan {
  margin: 0px;
}

.item-picker .ant-form-item .ant-form-vertical {
  padding-bottom: 0px;
  width: 500px;
}

.shift-type-modal .ant-modal-title {
  font-size: 14px;
  font-weight: bold;
  color: #1d3557;
}

.shift-type-modal label {
  font-size: 13px;
  font-weight: unset;
}

.button-adap {
  width: 125px;
  background-color: #1d3557;
}

.button-save {
  width: 125px;
  min-width: 117px;
  background-color: #1d3557;
  color: #ffffff;
}

.button-save:hover {
  background-color: #ffffff;
  color: #1d3557;
}

.onTop {
  height: 100%;
  vertical-align: top;
}

.shift-card {
  min-height: calc(100vh - 112px);
}

.shift-type-tree-select {
  display: inline-block;
}

.shift-type-tree-select .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.shift-type-tree-select .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}

.shift-type-tree-select .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.shift-type-tree-select-modal {
  max-width: 460px;
  min-width: 200px;
  margin-left: 10px;
  display: inline-block;
}

.shift-type-tree-select-modal .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.shift-type-tree-select-modal .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}

.shift-type-tree-select-modal .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.shift-type-inputNumber {
  /* border: unset !important; */
  text-align: right;
  width: 100%;
}

.shift-type-inputNumber.ant-input-number-focused {
  box-shadow: 0 0 0 1px #e5e5e5 !important;
}

.shift-type-inputNumber .ant-input-number-handler-wrap {
  display: none;
}

.shift-hover-icon-pluse {
  color: black;
}

.shift-hover-icon-pluse:hover {
  color: #1d3557;
}

.shift-type-form-setting .ant-form-item-label > label::after {
  content: unset;
}

.shift-type-form-setting .ant-form-item-label {
  line-height: unset;
}

.shift-type-form-setting2 .ant-form-item-label {
  padding-bottom: 2px;
}

.shift-type-form-setting label {
  margin-bottom: 8px;
  font-size: 12px;
  color: #1d3557;
  font-weight: normal;
}
