.selectedDate {
  background: #e0e7f5;
  color: #1d3557;
}

.calendar {
  width: 100%;
  max-width: 440px;
}

.site-calendar-demo-card {
  width: 440px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
}

.calendar-card {
  text-align: center;
}
