.collapse-menu.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}

.collapse-menu.ant-collapse {
  border: 0px solid #e5e5e5;
  border-bottom: 0;
  border-radius: 4px;
  background-color: #fff;
}

.collapse-menu.ant-collapse > .ant-collapse-item {
  border-bottom: 0px solid #e5e5e5;
}

.collapse-menu.ant-collapse-content {
  border-top: 0px solid #e5e5e5;
}

.collapse-menu.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #fff;
}

.collapse-menu.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 6px;
  padding-right: 20%;
}
.collapse-menu.ant-collapse-item:last-child > .ant-collapse-content {
  padding-bottom: 2%;
}

.collapse-menu.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  padding-bottom: 0.2%;
  padding-left: 0px;
}
.collapse-menu.ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px;
  padding-right: 0%;
  padding-bottom: 16px;
  padding-left: 0%;
}

.collapse-menu.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  margin-top: 0px;
}

.ant-card-actions {
  background: #ffffff;
  border-top: 0.5px solid #e8e8e8;
}

.text-managebt-style {
  color: #1d3557;
}

.text-managebt-style:hover {
  color: #e73845;
}

.collapse-menu.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  font-size: 11px;
}

.collapse-menu.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: unset;
  padding: 12px 16px 10px 0px !important;
}

.signOut-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #6490cf;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.signOut-modal-confirm .ant-btn-primary:hover {
  color: #6490cf;
  background-color: #ffffff;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.signOut-modal-confirm .ant-btn-dashed {
  color: #6490cf;
  background-color: #ffffff;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.signOut-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #6490cf;
  border-radius: 8px;
  border: 1px solid #6490cf;
}
