.input-search {
  /* width: 273px; */
  width: 60%;
}

.select-search {
  /* width: 153px; */
  width: 40%;
}

.position-right {
  float: right;
}

.filter-column {
  /* width: 153px; */
  width: 43%;
  float: right;
}

.button-customer {
  width: 120px;
  float: right;
}

.customer-list-left {
  width: 70%;
  margin-bottom: 16px;
  padding: initial;
}

.customer-select {
  width: 250px;
  font-size: 13px;
}

.customer-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.customer-rangePicker .ant-input {
  border: unset;
  color: #1d3557;
  font-size: 16px;
}

/* .customer-rangePicker .ant-calendar-range-middle{
  left : 50% !important;
} */

.customer-rangePicker .ant-input-lg {
  height: unset;
}

.total-items-customer {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.button-link-export {
  color: #1d3557;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;
}

.button-link-export:hover {
  border-color: transparent;
  color: #1d3557;
}

.button-link-export:active {
  border-color: transparent;
  color: #1d3557;
}

.button-link-export:focus {
  color: #1d3557;
  border-color: transparent;
}

.icon-excel {
  width: 25px;
  height: 25px;
}

.customer-card {
  /* height: 90vh; */
  /* min-height: 92vmin; */
  /* max-height: 100vmax; */
  min-height: calc(100vh - 112px);
}

.padding-row {
  padding: 0px 0px 24px 0px;
}
