.ant-popover-inner-content {
    /* padding: 0.2% 2%; */
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
  }

  .avatar-style {
    width: 100%;
    height: 100%;
  }

  .popover-style .ant-popover {
    width: 200px !important;
  }