.e-schedule.schedule-new-scheduleComponent {
  max-height: calc(100vh - 285px) !important;
  height: fit-content;
}

.schedule-new-scheduleComponent.e-schedule .e-timeline-view .e-content-wrap {
  height: 100% !important;
  max-height: calc(100vh - 404px) !important;
}

.schedule-new-scheduleComponent.e-schedule .template-wrap .resource-details .resource-name {
  font-size: 16px;
  font-weight: 500;
  margin-top: 5px;
}

.schedule-new-scheduleComponent.e-schedule .e-timeline-view .e-resource-left-td {
  width: 280px !important;
}

.schedule-new-scheduleComponent.e-schedule .template-wrap .resource-details {
  padding-left: 10px;
}

.schedule-new-scheduleComponent.e-schedule.e-device .template-wrap .resource-details .resource-designation {
  display: none;
}

.schedule-new-scheduleComponent.e-schedule .e-resource-text {
  align-items: center;
  display: block;
  margin-left: unset !important;
  /* white-space: nowrap; 
  width: 50px; 
  overflow: hidden;
  text-overflow: ellipsis; */
}

.schedule-new-scheduleComponent.e-schedule .e-timeline-view .e-navigate {
  padding-left: 5px !important;
}

.schedule-new-scheduleComponent colgroup col {
  width: 60px !important;
}

.e-schedule .e-timeline-view .e-resource-cells {
  padding-left: 20px;
}

.e-schedule.schedule-new-scheduleComponent .e-time-slots {
  padding: 0px 0px 0px 2px !important;
}

.e-schedule .e-timeline-view .e-content-wrap {
  height: 100%;
}

/*------------------------------- Gantt Header schedule-header-bar CSS --------------------------------- */

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-tbar-btn .e-tbar-btn-text {
  text-transform: none !important;
}

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-today {
  display: none !important;
}

.schedule-new-scheduleComponent.e-schedule .e-timeline-month-view .e-date-header-wrap table tbody td > span {
  padding-left: 5px;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #1d3557;
  border: 1px solid #1d3557;
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #1d3557;
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  border: 1px solid #1d3557;
  color: #1d3557;
}

.schedule-new-scheduleComponent.e-schedule .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  color: #bcc5d3;
  font-size: 13px;
  font-weight: 500;
}

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-toolbar-item.e-date-range .e-tbar-btn .e-tbar-btn-text {
  color: #1d3557 !important;
  font-size: 13px;
  font-weight: 500;
}

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1d3557 !important;
  font-size: 13px;
  font-weight: 500;
}

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-schedule-timeScale-15m .e-active-view .e-tbar-btn-text,
.e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1d3557 !important;
  font-size: 13px;
  font-weight: 500;
}

/* .schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-schedule-timeScale-15m .e-active-view .e-tbar-btn-text, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  color: #1D3557 !important;
  font-size: 13px;
  font-weight: 500;
} */

/* .schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-15m, .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
  background-color: #1D3557 !important;
  color: #1D3557 !important;
} */

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-15m {
  float: left;
}

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-30m {
  float: left;
}

.schedule-new-scheduleComponent.e-schedule .e-schedule-toolbar .e-toolbar-items .e-schedule-timeScale-1h {
  float: left;
}

.schedule-new-scheduleComponent.e-schedule .e-spinner-pane {
  display: none !important;
}

.schedule-new-scheduleComponent.e-schedule .e-timeline-view .e-resource-tree-icon {
  padding: 1px 7px !important;
}

.no-data-blank-svg {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  height: calc(100vh - 240px);
}

.no-data-blank-svg-img {
  width: 240px;
  /* height: calc(100vh - 450px); */
}

.no-data-blank-div {
  align-items: center;
  text-align: center;
  padding-top: 90px;
}

.no-data-blank-text-1 {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}

.no-data-blank-text-2 {
  font-size: 14px;
  font-weight: normal;
  color: #9a9999;
  margin-top: 15px;
}
