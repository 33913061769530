.task-detail-information-div-padding {
  padding: 10px 28px 28px 28px;
}

.task-detail-information-form-item .ant-form-item-label > label::after {
  content: unset;
}

.task-detail-information-form-item .ant-form-item-label {
  line-height: unset;
}

.task-detail-information-form-item label {
  margin: unset;
  font-size: 12px;
  color: #1d3557;
  font-weight: normal;
}

.task-detail-information-text-value {
  font-size: 13px;
  color: #050505;
  font-weight: normal;
}

.task-detail-information-text-address-value {
  font-size: 13px;
  color: #1d3557;
  font-weight: normal;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-top: 6px;
  line-height: 24px;
}

.task-detail-information-input-style .ant-input[disabled] {
  border-radius: 2px !important;
  border: solid 1px #bcc5d3 !important;
  background-color: #f2f3f5 !important;
}

.task-detail-information-text-icon {
  width: 18px;
  height: 18px;
}

.task-detail-information-text-address {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}

.task-detail-information-vertical-icon {
  padding: 25px 20px 0px 20px;
  text-align: center;
}

.task-detail-information-input-style .ant-input[disabled] {
  border-radius: 2px !important;
  border: solid 1px #bcc5d3 !important;
  background-color: #f2f3f5 !important;
}
