.blank-signature-content {
  background: #f5f5f5;
  height: 165px;
  text-align: center;
  justify-content: center;
  align-content: center;
  display: grid;
  border: solid 1px #e5e5e5;
}

.blank-signature-icon {
  width: 52px;
  height: 41px;
  margin-bottom: 5px;
}

.blank-signature-text {
  font-size: 10px;
  color: #9a9999;
  font-weight: 600;
  text-align: center;
  line-height: 2.29;
}

.uploader-image-background-main .ant-upload-list-picture-card .ant-upload-list-item {
  width: 100%;
  /* height: calc(100vh - 471px); */
}

.uploader-image-background-main .ant-upload-list-picture-card-container {
  width: auto;
  /* height: calc(100vh - 471px); */
}

.uploader-image-background-main {
  display: flex;
  justify-content: center;
}
