.checkable-tag-orange {
  cursor: pointer;
  width: 108px;
  border-radius: 16px;
  padding: 0px 16px;
  border: solid 1px #f88512;
  background-color: #f88512;
}

.checkable-tag-orange.ant-tag-checkable:not(.ant-tag-checkable-checked) {
  width: 108px;
  border-radius: 16px;
  padding: 0px 16px;
  color: #f88512;
  border: solid 1px #f88512;
  background-color: #ffffff;
}

.checkable-tag-green {
  cursor: pointer;
  width: 108px;
  border-radius: 16px;
  padding: 0px 16px;
  border: solid 1px #79c749;
  background-color: #79c749;
}

.checkable-tag-green.ant-tag-checkable:not(.ant-tag-checkable-checked) {
  width: 108px;
  border-radius: 16px;
  padding: 0px 16px;
  color: #79c749;
  border: solid 1px #79c749;
  background-color: #ffffff;
}

.checkable-tag-bule {
  cursor: pointer;
  width: 108px;
  border-radius: 16px;
  padding: 0px 16px;
  border: solid 1px #1890ff;
  background-color: #1890ff;
}

.checkable-tag-bule.ant-tag-checkable:not(.ant-tag-checkable-checked) {
  width: 108px;
  border-radius: 16px;
  padding: 0px 16px;
  color: #1890ff;
  border: solid 1px #1890ff;
  background-color: #ffffff;
}