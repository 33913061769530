.div-style {
  height: 236px;
}

.select-style {
  width: 160px;
  margin-bottom: 10px;
}

.text-customer {
  color: #1d3557;
  font-size: 14px;
}

.align-text-right {
  text-align: right;
}

.text-invoice {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #1d3557;
  margin-bottom: 19px;
}

.text-invoice-date {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #1d3557;
  margin-bottom: 80px;
}

.button-select-task {
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #1d3557;
}

.customer-select {
  /* margin-right: 0px; */
  /* width: 140px; */
  color: black;
}

.customer-select .ant-select-selection {
  border: unset;
}

.customer-select .ant-select-selection:focus {
  box-shadow: unset;
}
.customer-select .ant-select-selection__rendered {
  margin: unset;
  font-size: 20px;
  margin-right: 25px;
}
.customer-select .ant-select-selection__placeholder {
  font-size: 20px;
}
.customer-select .ant-select-selection--single {
  width: 190px;
  background-color: white;
  height: fit-content;
}
