.action-select-date-list-monitor {
  padding: 16px;
  width: 100%;
  border-top: 1px solid #e4e6eb;
}

.action-select-datepicker-list-title {
  padding-bottom: 6px;
}

.action-select-datepicker-list.ant-calendar-picker {
  width: 100%;
}

.action-select-datepicker-list-div {
  padding-bottom: 14px;
}
