.assignee-task-details-card {
  margin-bottom: 10px;
}

.styte-task-details {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 30px;
}

.styte-details-address-card {
  display: flex;
  justify-content: left;
}

.styte-details-text-p {
  font-size: 13px;
  padding-left: 0px;
  margin: 0px 5px 0px 0px;
  color: #1d3557;
  font-weight: 600;
  display: contents;
}

.container-detail-task {
  color: #1d3557;
  margin: 0px 0px 0px 5px;
  text-align: center;
  margin-right: 15px;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  display: contents;
}

.container-detail-task-address {
  color: #1d3557;
  margin: 0px 0px 0px 5px;
  text-align: center;
  font-size: 13px;
  margin-right: 15px;
  justify-content: center;
  align-items: center;
  display: contents;
}

.content-header {
  display: flex;
  margin-bottom: 10px;
}

.content-header-avatar {
  margin-right: 10px;
}

.content-header-name {
  margin-right: 60px;
  color: #1d3557;
}

.content-header-text-p-phone {
  margin-bottom: 5px;
  font-size: 12px;
}

.content-header-text-p-name {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}
