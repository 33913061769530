.asset-select-team .ant-select-selection--multiple {
  padding-bottom: 1px;
  border-radius: 5px;
}

.asset-select-team.ant-select {
  min-width: 200px;
  max-width: 270px;
}

.asset-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1d3557;
  border: 1px solid #1d3557;
}

.asset-action-search-all {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0px 12px 0px;
}

.style-buttun-columns {
  margin: 0px 0px 0px 10px;
}
