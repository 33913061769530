.calculate-col {
  margin-bottom: 8px;
}

.calculate-money {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.calculate-money-left {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.calculate-money-right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.calculate-money-discount-select {
  width: 80px !important;
}

.calculate-money-discount-select .ant-select-selection--single {
  border: unset;
}

.calculate-money-discount-div {
  display: flex;
  align-items: center;
}

.calculate-money-discount-input {
  border: unset;
  text-align: right;
  width: 120px;
}

.calculate-money-discount-input .ant-input-number-handler-wrap {
  display: none;
}

.calculate-money-discount-input .ant-input-number-input {
  text-align: end;
  padding: 0px 0px 0px 11px !important;
}

.calculate-text {
  font-size: 13px;
  color: #1d3557;
}
