.task-detail-assignee-padding {
  padding: 0px 12px 12px 12px;
}

.task-detail-assignee-text-name {
  font-size: 13px;
  color: #1d3557;
}

.task-detail-assignee-text-phone {
  font-size: 12px;
  color: #707070;
  margin-bottom: 5px;
}

.task-detail-assignee-icon {
  font-size: 32px;
  color: #bcc5d3;
}

.task-detail-assignee-padding-div {
  text-align: center;
  height: 200px;
  padding: 60px 39px 12px 39px;
}

.task-detail-assignee-text {
  font-size: 13px;
  font-weight: 600;
  color: #1d3557;
}

.task-detail-assignee-icon-selected .ant-badge .ant-scroll-number-custom-component {
  right: 4px;
  top: 32px;
  background: white;
  border-radius: 9px;
  border: 1px solid white;
}

.task-detail-assignee-icon-plus {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.task-detail-assignee-padding-value {
  padding: 0px 0px 15px 0px;
  display: flex;
}

.task-detail-assignee-detail {
  cursor: pointer;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.task-detail-assignee-detail-disable {
  cursor: pointer;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  opacity: 0.4;
  /* cursor: pointer; */
}
