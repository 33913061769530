.crad-default {
}

.crad-default-card {
  background: #f5f5f5;
  height: calc(100vh - 268px);
  border: unset;
  justify-content: center;
  align-items: center;
  display: flex;
}

.crad-default-card-v2 {
  background: #f5f5f5;
  height: calc(100vh - 216px);
  border: unset;
  justify-content: center;
  align-items: center;
  display: flex;
}

.crad-default-card-body {
  display: grid;
}

.crad-default-card-icon {
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 32px !important;
  color: #9a9999 !important;
  margin-bottom: 26px;
}

.crad-default-card-v3-header {
  font-size: 14px;
  color: #1d3557;
  font-weight: 600;
  text-align: center;
  margin-bottom: 16px;
}

.crad-default-card-v3-title {
  font-size: 14px;
  color: #1d3557;
  text-align: center;
  line-height: 1.43;
}

.crad-default-card-title {
  font-size: 14px;
  color: #9a9999;
  font-weight: 600;
  text-align: center;
  line-height: 30px;
}

.crad-default-card-icon {
}

.crad-default-card-text {
}

.crad-default-card-v3 {
  height: calc(100vh - 249px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.crad-default-card-v3 .ant-card-body {
  padding: unset !important;
}

.crad-default-card-file img {
  width: 100%;
}
