.monitor-summary-body {
  height: calc(100vh - 112px);
}

.monitor-summary-body .ant-card-head {
  font-size: 14px;
  font-weight: 600;
  color: #1d3557;
}

.monitor-summary-body .ant-card-body {
  padding: unset;
}

.card-tabs-manu-monitor .ant-tabs-bar {
  margin: unset;
}

.monitor-summary-detail-col {
  text-align: center;
  padding: 24px;
}

.monitor-summary-Row {
  padding: 24px;
  display: flex;
  width: 100%;
}

.monitor-summary-detail-tasks {
  width: 31%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.monitor-summary-detail-warning {
  width: 31%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.monitor-summary-detail-timeout {
  width: 31%;
  display: grid;
  align-items: center;
  justify-content: center;
}

/* .monitor-summary-col-content {
  display: grid;
  align-items: center;
  justify-content: center;
} */

.monitor-summary-col-p {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
}

.monitor-summary-col-p-warning {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  color: #f88512;
}

.monitor-summary-col-p-timeout {
  font-size: 17px;
  text-align: center;
  font-weight: 600;
  color: #f3442e;
}

.monitor-summary-col-text-tasks {
  font-size: 13px;
  color: #1d3557;
}

.monitor-summary-col-text-warning {
  font-size: 13px;
  color: #f88512;
}

.monitor-summary-col-text-timeout {
  font-size: 13px;
  color: #f3442e;
}

.monitor-summary-text-status {
  padding: 24px;
  background-color: #f5f5f5;
  height: 40px;
  justify-content: left;
  align-items: center;
  display: flex;
}

.monitor-summary-text-p-status {
  font-size: 15px;
  color: #1d3557;
  font-weight: 500;
}

.monitor-summary-body-status {
  padding: 0px 24px;
  height: calc(100vh - 360px);
  overflow-y: auto;
  overflow-x: hidden;
}

.monitor-summary-body-status::-webkit-scrollbar {
  width: 6px;
}

.monitor-summary-body-status::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.monitor-summary-body-status::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.monitor-summary-body-status::-webkit-scrollbar-thumb:hover {
  /* background: rgb(100, 100, 100); */
  background: #b3b3b3;
  border-radius: 8px;
}

.monitor-summary-body-status-name {
  font-size: 13px;
  font-weight: 500;
  color: #1d3557;
}

.monitor-summary-body-status-tag {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.monitor-summary-body-status-quantity {
  float: right;
  padding-right: 10px;
}

.monitor-summary-body-status-col-tag {
  justify-content: center;
  align-items: center;
  display: flex;
}

.monitor-summary-divider {
  height: 74px;
}
