.div-style-summary {
  height: 178px;
}

.text-area-style {
  height: 104px;
}

.align-text-center {
  text-align: center;
}

.align-text-right {
  text-align: right;
}

.margin-title {
  margin-right: 68px;
}

.margin-select {
  /* margin-right: 0px; */
  /* width: 30px; */
}

.margin-select .ant-select-selection {
  border: unset;
}

.margin-select .ant-select-selection:focus {
  box-shadow: unset;
}
.margin-select .ant-select-selection__rendered {
  margin: unset;
}
.margin-select .ant-select-selection__placeholder {
  font-size: 13px;
}
.margin-select .ant-select-selection--single {
  width: 60px;
  background-color: white;
  height: fit-content;
}

.margin-row {
  margin-bottom: 9px;
}

.margin-row-summary {
  margin-bottom: 16px;
}

.input-bath {
  border: unset;
  padding: unset;
  width: 66px;
  height: 25px;
  text-align: right;
}
