.label-from .ant-form-item-required::before {
  content: none;
}

.addArea-select-tag .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.addArea-select-tag .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}
