.report-table .ant-pagination-total-text {
  margin-right: -16px;
}

.report-table .ant-table-body {
  overflow: auto !important;
}

.report-rangePicker .ant-input {
  width: 290px;
  border: unset;
  color: #1d3557;
  font-size: 14px;
}

.report-rangePicker .ant-input-lg {
  height: unset;
}
