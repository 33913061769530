.task-pool-div-input {
  padding: 0px 24px 0px 24px;
}

.task-pool-div-treeViewComponent {
  margin: 2px 0px 8px 10px;
  padding: 13px 10px;
  /* max-height: calc(100vh - 280px); */
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

.task-pool-div-treeViewComponent::-webkit-scrollbar {
  width: 6px;
}

.task-pool-div-treeViewComponent::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.task-pool-div-treeViewComponent::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.task-pool-div-treeViewComponent::-webkit-scrollbar-thumb:hover {
  /* background: rgb(100, 100, 100); */
  background: #b3b3b3;
  border-radius: 8px;
}

.treeview-external-drag #waiting {
  height: 100%;
  padding: 0;
  cursor: pointer;
}

.treeview-external-drag #waitid {
  float: left;
  width: 13%;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  font-family: 'Segoe UI';
  color: #f9920b;
  overflow: hidden;
  margin: 9px 0;
  padding: 0 2px 0 0;
}

.treeview-external-drag #waitdetails {
  width: 95%;
  float: left;
  height: 100%;
  padding: 0;
}

.task-pool-text-no-p-taskNo {
  font-size: 14px;
  color: #1d3557;
  letter-spacing: normal;
  padding-right: 8px;
}

.task-pool-text-no-p-customerName {
  font-size: 13px;
  color: #1d3557;
  letter-spacing: normal;
  padding-left: 8px;
}

.task-pool-divider {
  margin: 10px 0px;
}

.task-pool-dot {
  margin: 0px 5px;
  position: relative;
  width: 2px;
  height: 2px;
  background-color: #1d3557;
  border-radius: 50%;
  display: inline-block;
}

.task-pool-badge {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  display: inline-block;
}

.task-pool-badge .ant-badge-status {
  line-height: unset;
}

.task-pool-badge .ant-badge-status-text {
  margin-left: unset;
}

.treeview-external-drag #waitcategory {
  height: auto;
  font-size: 12px;
  color: #1d3557;
  padding-left: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.task-pool-text-time {
  float: right;
}

.treeview-external-drag .e-list-text,
.treeview-external-drag.e-rtl .e-list-text,
.e-bigger .treeview-external-drag .e-list-text,
.e-bigger .treeview-external-drag.e-rtl .e-list-text {
  background: transparent;
  height: auto;
  line-height: 2.15;
  width: 100%;
}

.treeview-external-drag .e-list-parent,
.treeview-external-drag.e-rtl .e-list-parent,
.e-bigger .treeview-external-drag .e-list-parent,
.e-bigger .treeview-external-drag.e-rtl .e-list-parent {
  height: 100%;
  padding: 0px;
}

.treeview-external-drag .e-list-item,
.e-bigger .treeview-external-drag .e-list-item {
  height: 100%;
}

.treeview-external-drag .e-fullrow,
.e-bigger .treeview-external-drag .e-fullrow {
  height: 100%;
}

.treeview-external-drag .e-list-item.e-hover > .e-fullrow,
.treeview-external-drag .e-list-item.e-active > .e-fullrow,
.treeview-external-drag .e-list-item.e-active.e-hover > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-hover > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active > .e-fullrow,
.e-bigger .treeview-external-drag .e-list-item.e-active.e-hover > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none !important;
}

.treeview-external-drag .e-text-content,
.e-bigger .treeview-external-drag .e-text-content,
.treeview-external-drag.e-rtl .e-text-content,
.e-bigger .treeview-external-drag.e-rtl .e-text-content {
  padding: 0;
}

.e-drag-item.e-treeview.treeview-external-drag,
.e-bigger .e-drag-item.e-treeview.treeview-external-drag {
  padding: 0 !important;
}

@media (max-width: 550px) {
  .drag-sample-wrapper {
    display: block;
  }

  .schedule-container {
    padding-bottom: 10px;
  }

  .treeview-external-drag.e-treeview,
  .e-bigger .treeview-external-drag.e-treeview {
    width: 225px;
  }

  .e-bigger .treeview-external-drag.e-treeview.e-drag-item {
    position: relative !important;
  }
}

.task-pool-confirm-assign-task .ant-modal-confirm-body {
  text-align: center;
}

.task-pool-confirm-assign-task .ant-modal-confirm-btns {
  float: inherit;
  margin-top: 24px;
  justify-content: center;
  display: flex;
}

.task-pool-confirm-assign-task .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.task-pool-confirm-assign-task .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.task-pool-confirm-assign-task .ant-btn-danger {
  color: #1d3557 !important;
  background-color: #ffffff !important;
  border: 1px solid #1d3557 !important;
  border-radius: 2px !important;
}

.task-pool-confirm-assign-task .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #1d3557 !important;
  border-radius: 2px !important;
  border: 1px solid #1d3557 !important;
}

.filterSvg {
  height: 15px;
  width: 15px;
  margin-top: -12px;
  filter: opacity(0.5);
}

.header-taskpool {
  height: 10px;
}

.header-style .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: white;
}

.header-style .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}

.header-style .ant-collapse {
  border: 0px solid #e5e5e5;
  border-bottom: 0;
  border-radius: 4px;
  background-color: #fff;
}

.header-style .ant-collapse > .ant-collapse-item {
  border-bottom: 0px solid #e5e5e5;
}

.header-style .ant-collapse-content {
  border-top: 0px solid #e5e5e5;
}

.header-style .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #fff;
}

.header-style .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 6px;
  padding-right: 20%;
}

.header-style .ant-collapse-item:last-child > .ant-collapse-content {
  background-color: rgba(217, 217, 217, 0);
}

.header-style .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  padding-bottom: 0.2%;
  padding-left: 0px;
}

.header-style .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: none;
}

.header-style .ant-collapse-item {
  margin: unset !important;
}

.header-style.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
}

.header-style.ant-collapse {
  border: unset !important;
}

.header-style.ant-collapse > .ant-collapse-item {
  border-bottom: unset !important;
}

.header-style-panel {
  margin-top: 15px;
}

.header-style .ant-collapse-item:last-child > .ant-collapse-content {
  background-color: #f5f5f5;
  width: 100%;
}

.header-style .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px;
  padding-right: 0%;
  padding-bottom: 2px;
  padding-left: 0%;
  border-bottom: 1px solid #e8e8e8;
}

.task-pool-date.ant-calendar-picker {
  width: 100%;
  padding-bottom: 6px;
}

.task-pool-date .ant-calendar-input-wrap {
  display: none;
}

.task-pool-date .ant-calendar-picker-input.ant-input {
  border: none;
  padding-left: 5px;
  padding-bottom: 1px;
  cursor: pointer;
  padding-right: unset;
  background-color: rgba(217, 217, 217, 0);
}

.task-pool-input {
  margin-bottom: 10px;
}

.text-result-search {
  color: #bcc5d3;
}
.color-filter {
  color: #1d3557;
  font-weight: 600;
}

.dot-filter .request-badge .ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  right: 2px;
  top: 9px;
}

.bottom-div {
  width: 100%;
}

.all-object {
  height: calc(100vh - 170px);
  display: flex;
  flex-direction: column;
}

.text-drag {
  color: #bcc5d3;
  margin-bottom: 5px;
  font-size: 12px;
  padding-top: 4px;
}

.text-drag2 {
  color: #bcc5d3;
  font-size: 12px;
}

.div-text-center {
  text-align: center;
  padding-bottom: 18px;
}

.row-center-text {
  display: flex !important;
  justify-content: center;
}

.over-text-long {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.space-taskno-org {
  display: flex;
  justify-content: space-between;
}

.task-pool-text-team {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100px;
}
