.orderMenuBackground {
  /* background-color: #f2f3f5; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* min-height: 100vh; */
}

.orderMenuCardBackground {
  background-color: #ffffff;
  /* min-height: 1032px; */
  width: 700px;
  border-radius: 2px;
  /* box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); */
}

.orderMenuCard {
  border-radius: 8px;
}

.orderMenuCardText {
  font-size: 1em;
  color: #3B3B3B;
  font-weight: 900;
}

.orderMenuCardSummary {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.orderMenuCardSummaryTitle {
  font-size: 1em;
  color: #505050;
}


.orderMenuCardSummaryValue {
  font-size: 1.2em;
  color: #505050;
  font-weight: 900;
}