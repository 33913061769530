.workDay-checkbox .ant-checkbox+span {
  padding-right: 30px;
}

.letter-space .ant-card-body {
  padding-left: 45px;
}

.row-space .ant-form-item-label {
  padding-bottom: 20px;
}

.holidays-form-item label::after {
  content: none;
}

.holidays-form-item label {
  font-size: 13px;
}

.button-font-size label {
  font-size: 13px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #1d3557;
}

.border-card-bottom {
  border-bottom-style: none;
}

.checkbox-border .ant-checkbox-inner {
  /* border: 1px solid #1d3557 !important; */
}

.holiday-card {
  height: 90vh;
}