.invoice-list-left {
  width: 70%;
  margin-bottom: 16px;
  padding: initial;
}

.invoice-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.invoice-select {
  width: 250px;
  font-size: 13px;
}

.invoice-rangePicker .ant-input {
  width: 290px;
  border: unset;
  color: #1d3557;
  font-size: 14px;
}

.invoice-rangePicker .ant-input-lg {
  height: unset;
}
