.action-search-all {
  display: flex;
}

.monitor-view-input-action {
  width: 175px;
}

.action-select-team-monitor {
}

.monitor-select-team {
  width: 240px;
  min-width: 240px;
}

.monitor-select-team .ant-select-selection--multiple {
  /* padding-bottom: 0px; */
}

.monitor-select-team {
  width: auto;
  display: inline-block;
}

.monitor-select-team .ant-select-selection--multiple .ant-select-selection__choice {
  color: #1d3557;
  border: 1px solid #1d3557;
}

.monitor-select-team .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.action-date-monitor .ant-input {
  border: unset;
  width: 165px;
}

.status-item {
  display: flex;
  padding: 4px 18px 0px 0px;
  align-items: center;
}

.status-item-block .ant-badge-status-dot {
  width: 16px;
  height: 16px;
  border-radius: 2px;
}

.status-item-name {
  font-size: 12px;
}

.footer-status-group-monitor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-status-group-list-sla {
  display: flex;
}

.footer-status-group-list {
  display: flex;
}

.icon-sla-monitor.anticon svg {
  width: 16px;
  height: 16px;
}

.icon-sla-monitor.anticon {
  padding-right: 5px;
}

.monitor-select-action .ant-select-selection--single {
  width: 150px;
}

.action-groupby-select {
  width: 150px;
  margin-right: 12px;
  height: 35.2px;
}

.action-groupby-select .ant-select-selection--single {
  width: 150px;
  margin-right: 12px;
  height: 35px;
}

.maximum-task-text-css {
  padding: 4px 8px 8px;
  color: #9a9999;
  display: flex;
  justify-content: center;
}
