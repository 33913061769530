.style-icon {
  width: 18px;
  height: 18px;
  color: #9a9999;
  fill: #9a9999;
}
.name-style {
  font-weight: 500;
  color: #1d3557;
}

.name-style-lognew {
  font-weight: 600;
}

.call-text {
  padding: 0px 3px;
}

.padding-col {
  padding: 12px 0px 8px;
  padding-left: 16px;
}

.padding-col-img {
  padding: 3px 0px 0px 0px;
}

.status-tag {
  border-radius: 10px;
  margin-right: 4px;
}

.space-dividerr.ant-divider-horizontal {
  margin: 8px 0px !important;
}

.space-text-icon {
  padding-left: 10px;
}

.style-div-work-log {
  padding: 0px 16px;
}
