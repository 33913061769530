.sale-oreder-modal-add-item {
}

.sale-oreder-modal-add-body {
}

.modal-add-form {
  display: flex;
}

.modal-add-form .ant-form-item-label > label::after {
  content: unset;
}

.sale-oreder-modal-add-item .ant-btn-danger {
  color: #6490cf !important;
  background-color: #ffffff !important;
  border: 1px solid #6490cf !important;
  border-radius: 8px !important;
  width: 96px;
}

.sale-oreder-modal-add-item .ant-btn-danger:hover {
  color: #ffffff !important;
  background-color: #6490cf !important;
  border-radius: 8px !important;
  border: 1px solid #6490cf !important;
}

.sale-oreder-modal-add-item .ant-btn-primary {
  color: #ffffff;
  background-color: #6490cf;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
  width: 96px;
}

.sale-oreder-modal-add-item .ant-btn-primary:hover {
  color: #6490cf;
  background-color: #ffffff;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}
