.margin-avartar {
  margin-left: 19px;
  cursor: pointer;
}

.div-style-unread {
  width: 115px;
  height: 21px;
  margin: 17px 19px 13px 5px;
  padding: 0 18px;
  border-radius: 11px;
  border: solid 1px #1d3557;
  color: #1d3557;
  font-size: 12px;
  text-align: center;
  display: inherit;
}

.div-style-acknowledge {
  width: 115px;
  height: 21px;
  margin: 17px 19px 13px 5px;
  padding: 0 18px;
  border-radius: 11px;
  border: solid 1px #21723e;
  color: #21723e;
  font-size: 12px;
  text-align: center;
  display: inherit;
}

.div-content-body {
  overflow: inherit;
  overflow-x: hidden;
  height: calc(100vh - 273px);
}

.task-notification-card {
  background: #f5f5f5;
  height: calc(100vh - 278px);
  border: unset;
  justify-content: center;
  align-items: center;
  display: flex;
}

/* .noti-table
  .ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  height: calc(100vh - 388px);
}

.noti-table
  .ant-table-fixed-header
  .ant-table-fixed-left
  .ant-table-body-inner {
  height: calc(100vh - 372px);
} */

.header-noti-detail .ant-card-head {
  min-height: 54px;
}

.header-noti-detail.ant-card-bordered {
  height: calc(100vh - 117px);
}

.table-notofication-page .ant-table-body {
  overflow: auto !important;
}
