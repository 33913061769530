.summary-list-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;
}

.summary-list-totals {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.task-color-summary {
  color: #1d3557;
}
/* 
.org-card-menu-tree {
  height: calc(100vh - 254px);
  overflow: auto !important;
  margin-left: 24px;
} */

.warning-color-summary {
  color: #f88512;
}

.delayed-color-summary {
  color: #f3442e;
}

.completed-color-summary {
  color: #21723e;
}

.filter-text-summary-text {
  padding: 12px 22px;
}

.filter-text-summary-text-size {
  color: #707070;
  font-size: 14px;
  font-weight: 600;
}

.filter-text-summary {
  padding: 12px 16px;
  color: #707070;
  font-size: 14px;
}

.filter-text-summary-div {
  border-top: none;
  border-bottom: 1px solid #e4e6eb;
  height: calc(100vh - 295px);
  overflow-y: auto;
  overflow-x: hidden;
}

.total-text-summary {
  padding: 0px 0px;
}

.summary-list-totals-number {
  font-size: 17px;
  font-weight: 600;
}

.summary-list-totals-text {
  font-size: 13px;
}

.member-mapview-list {
  padding: 0px 12px 16px 24px;
  height: calc(100vh - 231px);
  overflow-x: unset;
  overflow-y: auto;
}

.member-view-list {
  padding: 0px 12px 16px 24px;
  height: calc(100vh - 258px);
  overflow-x: unset;
  overflow-y: auto;
}

.member-item-div {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 10px 0px;
}

.member-item-div-image {
  padding-right: 12px;
}

.member-item-div-avatar {
  display: flex;
}

.member-item-div-detail {
  margin-top: -3px;
}

.member-item-div-name {
  font-size: 12px;
  font-weight: 500;
  color: #1d3557;
}

.member-item-div-phone {
  font-size: 12px;
  color: #1d3557;
}

.member-item-div-more {
}

.member-item-div-colon {
}

.popover-content {
  width: 130px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
}

.popover-content-info {
  cursor: pointer;
  font-size: 13px;
  padding: 6px 12px;
  width: 100%;
}

.collaps-content-style {
  background-color: white;
  border: unset;
}

.collaps-content-style .ant-collapse-content {
  border-top: unset;
}

.collaps-content-style .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  padding-bottom: unset;
}

.header-style-panel-new {
  border-bottom: unset !important;
}

.header-style-panel .ant-collapse-header {
  height: 50px;
}

.collaps-content-style .ant-collapse-content > .ant-collapse-content-box {
  padding: unset;
}

.select-text-panel-style-myrequest {
  font-size: 14px;
  font-weight: normal;
  margin-left: 20px;
  color: #9a9999;
}

.select-group-style .ant-checkbox-group-item {
  margin-right: 8px;
  padding: 14px 25px 5px 30px;
}

.select-group-style-map .ant-checkbox-group-item {
  margin-right: 8px;
  padding: 14px 25px 5px 30px;
}

.select-group-style-map.select-group-style {
  display: flex;
  flex-direction: column;
}

.select-group-style.select-group-style {
  display: flex;
  flex-direction: column;
}

.member-mapview-div-header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 6px 24px;
}

.member-mapview-div-header-list {
  display: flex;
}

.summary-list-row-totals-task {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin: 14px 22px;
}

.summary-list-row-totals-task-text {
  color: #1d3557;
  font-size: 14px;
  font-weight: 600;
}

.divider-summary-list-custom.ant-divider-horizontal {
  margin: 14px 0;
}
