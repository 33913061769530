.task-report-list-left {
  width: 70%;
  margin-bottom: 16px;
  padding: initial;
}

.task-report-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.task-report-select {
  width: 250px;
  font-size: 13px;
}

.total-items-task-report {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.button-link-export {
  color: #1d3557;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;
}

.button-link-export:active {
  color: #1d3557;
  border-color: transparent;
}

.button-link-export:hover {
  border-color: transparent;
  color: #1d3557;
}

.button-link-export:focus {
  color: #1d3557;
  border-color: transparent;
}

.icon-excel {
  width: 25px;
  height: 25px;
}

.task-report-card {
  /* height: 100vh; */
  /* min-height: 92vmin; */
  /* max-height: 100vmax; */
  min-height: calc(100vh - 112px);
}
