.engagement-post-card {
  margin-bottom: 16px;
}

.engagement-post-card .ant-card-body {
  padding: 24px 0 24px 0;
}

.engagement-post-head {
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
}

.engagement-post-create-post {
  padding: 0px 24px;
  margin: 20px 0 20px 0;
}

.engagement-post-head-left {
  display: flex;
  align-items: center;
}

.engagement-post-head-left-avatar {
  margin-right: 30px;
}

.engagement-post-left-text {
  line-height: 1.75;
}

.engagement-post-left-text-head {
  font-size: 13px;
  font-weight: 600;
  color: #1d3557;
}

.engagement-post-head-left-text {
  display: flex;
  align-items: center;
}

.engagement-post-text-11 {
  font-size: 12px;
  color: #707070;
}

.engagement-post-text-12 {
  font-size: 12px;
  color: #707070;
}

.engagement-post-text-13 {
  font-size: 13px;
  color: #1d3557;
}

.engagement-post-left-text-dot {
  margin: 0px 5px;
  top: -5px;
  position: relative;
}

.engagement-post-create-post {
  line-height: 2.36;
}

.engagement-post-image-row {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE10 */
  flex-wrap: wrap;
}

.engagement-post-image-column-1 {
  -ms-flex: 100%; /* IE10 */
  flex: 100%;
  max-width: 100%;
  padding: 0 1px;
  height: 415px;
}

.engagement-post-image-column-2 {
  -ms-flex: 50%; /* IE10 */
  flex: 50%;
  max-width: 50%;
  padding: 0 1px;
  height: 215px;
}

.post-tags {
  border-radius: 2px;
  border: solid 1px #1d3557;
  color: #1d3557;
  height: 22px !important;
  padding: 0px 8px;
  cursor: pointer;
}
