.task-detail-map-address-text {
  font-size: 13px;
  color: #1d3557;
  padding-top: 2px;
}

.task-detail-map-address-padding {
  padding: 17px 24px;
  display: flex;
}

.task-detail-map-address-padding-map {
  padding: 0px 24px;
}
