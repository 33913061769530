.modal-style {
  justify-content: center;
  width: 800px !important;
}
.cancel-text-style {
  color: #1d3557;
}
/* .modal-style .ant-btn{
    border: 1px solid #1D3557;
    border-radius: 0%;
} */

.modal-style .ant-btn:hover {
  /* box-shadow: 1px 1px 7px #40a9ff; */
}

.ant-select-selection--multiple {
  border-radius: 8px;
  /* height: 35px; */
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  height: 27px;
}
.tagify__tag__removeBtn {
  color: #0b0b0b;
}
.modal-style .ant-modal-body {
  padding: 20px 25px !important;
}
.role-text-style {
  text-align: left;
  font-size: 12px/24px;
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  margin-bottom: 10px;
}
.suggest-text-style {
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
  text-align: left;
  font-size: 12px/24px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  margin-bottom: 10px;
}
.title-modal-text {
  text-align: left;
  /* font-family: -apple-system, BlinkMacSystemFont, sans-serif; */
  font-size: 16px;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
}

.invitebtn_user_ex {
  margin: 0px 0px 0px 0px;
}

.invitebtn_user_ex {
  margin: 0px 0px 0px 0px;
}
