.label-edit-from .ant-form-item-required::before {
  content: none;
}

.edit-button {
  margin: 0px;
}

.edit-select-tag .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.edit-select-tag .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
}
