.price-report-col {
  justify-content: center;
  align-items: center;
  display: flex;
}

.price-report-div-text {
  text-align: center;
  line-height: 2.15;
}

.price-report-text-p {
  font-size: 13px;
  color: #1d3557;
}

.price-report-text-span {
  font-size: 24px;
  color: #1d3557;
}
