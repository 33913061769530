.div-blank-nodata {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.container-blank-nodata-report {
  position: absolute;
  top: 25%;
  bottom: 25%;
  left: 25%;
  right: 25%;
}

.header-blank-asset {
  font-size: 14px;
  color: #1d3557;
  font-weight: 600;
}

.body-blank-asset {
  font-size: 14px;
  color: #9a9999;
}
