.styled-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.styled-col-input {
  margin: 0;
  /* margin-left: 25px; */
  /* margin-left: 25px; */
}

.styled-col-select {
  margin: 0;
  /* margin-right: 25px; */
  /* margin-right: 25px; */
}

.styledAppSelect {
  margin-right: 20px;
  font-size: 13px;
  width: 250px;
}

.styledAppSelect .ant-select-selection--single {
  border-radius: 0px 5px 5px 0px;
  justify-content: left;
  align-items: center;
  display: flex;
}

.user-management-users-input .ant-input {
  border-radius: 5px 0px 0px 5px;
}

.styledPopDelete {
  width: 100px;
}

.ant-btn-background-ghost.ant-btn-link {
  color: rgba(0, 0, 0, 0.65);
}

.cutstom-a {
  color: rgb(107, 107, 107);
}

.p-style {
  width: 100% !important;
}

.action-delete {
  margin: unset !important;
}

.action-delete-span {
  justify-content: center;
  display: block;
  align-items: center;
}

.user-list .ant-table {
  -webkit-box-shadow: 0px 1px 2px 0px #00000033;
}

.action-delete-p {
  margin-bottom: 0px;
}

.users-modal-warning .ant-btn-primary {
  color: #ffffff;
  background-color: #6490cf;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.users-modal-warning .ant-btn-primary:hover {
  color: #6490cf;
  background-color: #ffffff;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.users-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #6490cf;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.users-modal-confirm .ant-btn-primary:hover {
  color: #6490cf;
  background-color: #ffffff;
  border-color: #6490cf;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.users-modal-confirm .ant-btn-dashed {
  color: #6490cf;
  background-color: #ffffff;
  border: 1px solid #6490cf;
  border-radius: 8px;
}

.users-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #6490cf;
  border-radius: 8px;
  border: 1px solid #6490cf;
}

.users-badge .ant-badge-status-text {
  font-size: 13px;
}

.user-management-users-card {
  height: calc(100vh - 112px);
}

.users-table .ant-table-body {
  overflow: auto !important;
}

/* .users-table .ant-table-fixed-header > .ant-table-content > .ant-table-scroll > .ant-table-body {
    max-height: calc(100vh - 372px) !important;
    overflow-y: auto !important;
  }
  
  .users-table .ant-table-fixed-header .ant-table-fixed-left .ant-table-body-inner {
    height: calc(100vh - 372px);
  } */
