.div-content-body-menu {
  overflow: inherit;
  overflow-x: hidden;
  height: calc(100vh - 264px);
  margin: 12px -24px 0px -24px;
}

.div-content-body-search {
}


