.menu-item-list .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #02fe39;
}

.premissions-tag {
  background-color: #ffffff;
  color: #1d3557;
  border: 1px solid #1d3557;
}

.ant-tag .anticon-close {
  color: #1d3557;
}

.permission-select .ant-select-selection--multiple .ant-select-selection__choice__remove {
  color: #1d3557;
}

.permission-select .ant-select-selection--multiple .ant-select-selection__choice {
  border: 1px solid #1d3557;
  color: #1d3557;
  background-color: #ffffff;
  border-radius: 4px;
}

.permission-select .ant-select-selection--multiple .ant-select-selection__choice__remove:hover {
  color: #1d3557;
}

.permission-manu .ant-menu-item::after {
  border-right: none;
}

.card-permission-function {
  min-height: calc(100vh - 112px);
}

.card-permission-function .ant-card-body {
  padding: 2px 0px 0px 0px;
  /* max-height: calc(100vh - 168px);
  overflow-y: auto;
  overflow-x: hidden; */
}

.card-permission-function .ant-menu-root.ant-menu-inline {
  border: unset;
}

.card-permission-permission {
  min-height: calc(100vh - 112px);
}

.card-permission-permission .ant-card-body {
  margin-top: 2px;
  padding: 22px 24px 24px 24px;
  max-height: calc(100vh - 170px) !important;
  overflow-y: auto !important;
}

.permission-body-card-list .ant-card-body {
  padding: 12px 24px 24px 24px;
}

/* ปรับขนาดของ Scroll bar ให้เป็นขนาดเล็ก */
.permission-manu {
  max-height: calc(100vh - 168px);
  overflow-y: auto;
  overflow-x: hidden;
}

.permission-manu::-webkit-scrollbar {
  width: 6px;
}

.permission-manu::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.permission-manu::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.permission-manu::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 8px;
}
