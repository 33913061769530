.map-control {
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffffff;
  width: 81px;
  height: 40px;
  margin: 10px;
  right: 0;
  z-index: 3;
  position: absolute;
}

.map-control-divider {
  height: 1.9em;
}

.map-control-icon-focus {
  color: #1d3557;
}

.map-control-icon-focus-default {
  color: #bcc5d3;
}

.map-control-icon-focus-task {
  color: #1d3557;
}
