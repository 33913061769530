.task-content-text-statusCode {
  background-color: #f5f5f5;
  padding: 16px;
}

.task-content-text-p-statusCode {
  font-size: 13px;
  color: #1d3557;
  margin: unset;
}

.task-content-text {
  font-size: 13px;
  color: #1d3557;
}

.task-content-text-p {
  font-size: 13px;
  color: #1d3557;
  margin: 0px 5px;
}

.task-content-content-container {
  padding: 0px 16px;
}

.task-content-styte-details {
  margin: 10px 0px 10px 0px;
  display: flex;
  align-items: center;
  justify-content: left;
}

.task-content-styte-details-address {
  display: flex;
  margin: 10px 0px;
}

.task-content-address-text-p {
  width: 215px;
  font-size: 13px;
  color: #1d3557;
  margin: 0px 5px;
}

.card-task {
  width: 320px;
  z-index: 1000;
}

.task-content-text-statusCode {
  cursor: pointer;
}

.font-all {
  font-size: 16px;
}

.assignee-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-assignee {
  /* display: flex;
  justify-content: center;
  align-items: center; */
}
