.role-row {
  align-items: stretch;
  align-content: stretch;
  display: flex;
}

.total-items-role {
  display: flex;
  position: relative;
  margin-top: -39px;
  margin-left: 10px;
}

.role-card-default {
  min-height: calc(100vh - 112px);
  justify-content: center;
  align-items: center;
  display: flex;
}

.role-div-default {
  display: grid;
}

.role-default-icon {
  font-size: 32px;
  color: #bcc5d3;
  margin-bottom: 26px;
}

.role-defaul-title {
  font-size: 14px;
  line-height: 30px;
  color: #1d3557;
}

.role-card-container {
  min-height: calc(100vh - 112px);
}

.role-card-container .ant-card-body {
  padding: unset;
}

.role-name-form .ant-form-item-required::before {
  content: none;
}

.role-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.role-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.role-modal-confirm .ant-btn-dashed {
  color: #1d3557;
  background-color: #ffffff;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.role-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #1d3557;
  border-radius: 2px;
  border: 1px solid #1d3557;
}

.ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #1d3557;
}

.userManagement-role-modal-confirm .ant-btn-primary {
  color: #ffffff;
  background-color: #1d3557;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userManagement-role-modal-confirm .ant-btn-primary:hover {
  color: #1d3557;
  background-color: #ffffff;
  border-color: #1d3557;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userManagement-role-modal-confirm .ant-btn-dashed {
  color: #1d3557;
  background-color: #ffffff;
  border: 1px solid #1d3557;
  border-radius: 2px;
}

.userManagement-role-modal-confirm .ant-btn-dashed:hover {
  color: #ffffff;
  background-color: #1d3557;
  border-radius: 2px;
  border: 1px solid #1d3557;
}

.input-search-role {
  padding: 0px 16px 16px 16px;
}

.input-search-role-prefix {
  padding: 0px 16px 16px 16px;
  color: rgba(0, 0, 0, 0.25);
}

.role-card-menu-tree {
  height: calc(100vh - 112px) !important;
}

.role-card-menu-tree .ant-card-body {
  padding: unset;
}

.list-view-list {
  width: 40%;
  padding: initial;
}

.role-content-body {
  overflow: inherit;
  overflow-x: hidden;
  height: calc(100vh - 195px);
}

.role-card-member .ant-card-body {
  padding: 24px;
}

.role-total-member {
  display: flex;
  position: relative;
  margin-top: -38px;
  margin-left: 10px;
}

.card-padding .ant-card-extra {
  padding-top: 7px !important;
}

.role-padding-style {
  padding: 24px 24px 0px 24px !important;
}

.div-padding-style {
  padding: 23px 0px 0px 0px;
}

.role-menu-directory {
  height: calc(100vh - 254px);
  overflow: auto !important;
}

/* ปรับขนาดของ Scroll bar ให้เป็นขนาดเล็ก */

.role-menu-directory::-webkit-scrollbar {
  width: 6px;
}

.role-menu-directory::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.role-menu-directory::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.role-menu-directory::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3;
  border-radius: 8px;
}
