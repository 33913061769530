.engagement-filter-card {
  height: calc(100vh - 116px);
}

.engagement-filter-card .ant-card-body {
  padding: unset !important;
}

.header-style-engage .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: white;
}

.header-style-engage .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 4px 4px;
}

.header-style-engage .ant-collapse {
  border: 0px solid #e5e5e5;
  border-bottom: 0;
  border-radius: 4px;
  background-color: #fff;
}

.header-style-engage .ant-collapse > .ant-collapse-item {
  border-bottom: 0px solid #e5e5e5;
}

.header-style-engage .ant-collapse-content {
  border-top: 0px solid #e5e5e5;
}

.header-style-engage .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background-color: #fff;
}

.header-style-engage .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 0px 6px;
  padding-right: 20%;
}

.header-style-engage .ant-collapse-item:last-child > .ant-collapse-content {
  background-color: rgba(217, 217, 217, 0);
}

.header-style-engage .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  padding-bottom: 0.2%;
  padding-left: 0px;
}

.header-style-engage .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: none;
}

.header-style-engage .ant-collapse-item {
  margin: unset !important;
}

.header-style-engage.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
}

.header-style-engage.ant-collapse {
  border: unset !important;
}

.header-style-engage.ant-collapse > .ant-collapse-item {
  border-bottom: unset !important;
}

.header-style-engage-panel {
  margin-top: 15px;
}

.header-style-engage .ant-collapse-item:last-child > .ant-collapse-content {
  background-color: #f5f5f5;
  width: 100%;
}

.header-style-engage .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 0px;
  padding-right: 0%;
  padding-bottom: 2px;
  padding-left: 0%;
  border-bottom: 1px solid #e8e8e8;
}
.engagement-filter-tabs .ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: white;
}

.engagement-filter-tabs .ant-tabs-bar {
  margin: 0;
}

.engagement-filter-tabs .ant-tabs-nav {
  width: 100%;
}

.engagement-filter-tabs .ant-tabs-nav .ant-tabs-tab-active {
  width: 50%;
}

.engagement-filter-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: unset;
  text-align: center;
  width: 50%;
  padding: 18px;
}

.engagement-filter-tabs.ant-tabs-top .ant-tabs-ink-bar-animated {
  width: 50%;
}

.customer-engagement-filter {
  padding: 16px;
}

.summary-customer-checked {
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  border-top: 0.5px solid #f2f3f5;
}

.summary-customer-checked2 {
  display: flex;
  justify-content: space-between;
  padding: 0px 16px 10px 16px;
}

.customer-engagement-list-all {
  padding: 0px 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
}

.customer-engagement-list-all::-webkit-scrollbar {
  width: 6px;
}

.customer-engagement-list-all::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.customer-engagement-list-all::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.customer-engagement-list-all::-webkit-scrollbar-thumb:hover {
  /* background: rgb(100, 100, 100); */
  background: #b3b3b3;
  border-radius: 8px;
}

.customer-engagement-list {
  cursor: pointer;
}

.padding-between-item-engage-list.ant-row {
  padding: 10px 0px;
}

.space-padding-select-filter {
  padding: 8px 0px;
}

.span-title-slect-filter {
  margin-bottom: 4px;
}

.postby-padding-member-list {
  padding: 0px 16px 16px 16px;
}

.all-object-engagement {
  height: calc(100vh - 176px);
  display: flex;
  flex-direction: column;
}

.color-icon-engage {
  color: #707070;
}

.custype-bustype-engage {
  color: #1d3557;
  font-size: 12px;
}

.phone-member-engage {
  color: #1d3557;
  font-size: 12px;
}

.badge-custype-bustype-engage .ant-badge-status-dot {
  width: 2px;
  height: 2px;
}

.badge-custype-bustype-engage.ant-badge {
  padding-left: 5px;
}

.engagement-hidden-text {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.postby-no-member {
  text-align: center;
  padding: 144px 0;
}

.divider-style-enge.ant-divider-horizontal {
  margin: 8px 0;
}

.header-style-panel-engage {
  margin-top: 0px;
}
