.schedule-cell-dimension-shift.e-schedule .e-timeline-month-view .e-resource-left-td {
  width: 230px;
}

.schedule-cell-dimension-shift.e-schedule .e-timeline-month-view .e-resource-cells {
  padding-left: 16px;
  padding-right: 16px;
}

.schedule-cell-dimension-shift.e-schedule .e-timeline-month-view .e-content-wrap {
  height: 100% !important;
}

/* .schedule-cell-dimension-shift.e-schedule .e-timeline-month-view .e-appointment .e-subject {
  padding-top: unset;
} */

/* .schedule-cell-dimension.e-schedule .e-vertical-view .e-date-header-wrap table col,
.schedule-cell-dimension.e-schedule .e-vertical-view .e-content-wrap table col {
    width: 100px;
} */