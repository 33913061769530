.over-time-report-list-left {
  width: 70%;
  margin-bottom: 16px;
  padding: initial;
}

.over-time-report-list-right {
  float: right;
  margin-bottom: 16px;
  padding: initial;
}

.over-time-report-select {
  width: 250px;
  font-size: 13px;
}

.total-items-overtime-report {
  display: flex;
  position: relative;
  margin-top: -42px;
  margin-left: 10px;
}

.button-link-export {
  color: #1d3557;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;
}

.button-link-export:active {
  color: #1d3557;
  border-color: transparent;
}

.button-link-export:hover {
  border-color: transparent;
  color: #1d3557;
}

.button-link-export:focus {
  color: #1d3557;
  border-color: transparent;
}

.icon-excel {
  width: 25px;
  height: 25px;
}

.overtime-report-card {
  /* height: 100vh; */
  /* min-height: 92vmin; */
  /* max-height: 100vmax; */
  min-height: calc(100vh - 112px);
}

.report-monthPicker-new .ant-input {
  width: 100px;
  border: unset;
  color: #1d3557;
  font-size: 14px;
}

.row-custom-layout {
  position: relative;
  height: auto;
  margin: -8px;
  padding-top: 0px;
  zoom: 1;
  display: flex;
  box-sizing: border-box;
}

.col-custom-layout-left {
  padding: 8px;
  display: block;
  box-sizing: border-box;
  width: 400px;
  min-width: 300px;
}

.col-custom-layout-left-none {
  display: none;
}

.col-custom-layout-right {
  padding: 8px;
  display: block;
  box-sizing: border-box;
  width: 100%;
}

.benefit-deduct-report-card {
}
