.menu-style {
  background: #ffffff;
  border-color: #fafafa;
  box-shadow: 1px 0px 2px #c4c4c4;
}

.sider-style {
  background: #ffffff;
  columns: #fafafa;
  box-shadow: 1px 0px 2px rgba(196, 196, 196, 0.699);
  flex: 0 0 224px !important;
  max-width: 224px;
  min-width: 45px !important;
  width: 20px;
  margin-right: 1px;
}

.menu-item-style {
  margin-bottom: 10px !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
  background: #ffffff;
  border-color: #fafafa;
  box-shadow: 1px 0px 2px #c4c4c4;
}

.menu-item-style.ant-menu-root.ant-menu-inline {
  height: calc(100vh - 67px);
  overflow: auto;
  overflow-x: hidden;
}

.menu-item-style::-webkit-scrollbar {
  width: 6px;
}

.menu-item-style::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.menu-item-style::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.menu-item-style::-webkit-scrollbar-thumb:hover {
  /* background: rgb(100, 100, 100); */
  background: #b3b3b3;
  border-radius: 8px;
}

.fold-menu {
  margin-bottom: 4.5px !important;
  margin-right: 20px;
  padding-left: 17px;
  padding-top: 5px;
  width: 16px;
  height: 16px;
}

.unfold-image {
  color: darkslategrey;
}

.unfold-menu {
  padding-right: 15px;
}

.unfold-menu .anticon svg {
  width: 16px;
  height: 16px;
}

.ant-menu-submenu-selected {
  color: #1d3557;
}

.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #1d3557 !important;
}

.ant-menu-item > a:hover {
  color: #1d3557;
}

.ant-menu-item-selected > a,
.ant-menu-item-selected > a:hover {
  color: #1d3557;
}

.ant-menu-item-selected {
  background-color: #e0e7f5;
}

.ant-menu-item > .ant-badge > a:hover {
  color: #1d3557;
}

.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before {
  background: -webkit-gradient(linear, left top, right top, from(#1d3557), to(#1d3557));
  background: linear-gradient(to right, #1d3557, #1d3557);
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

/* .ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin-bottom: 0px;
  margin-top: 1.8px;
} */

.ant-layout-sider-children {
  /* height: 100%; */
  margin-top: -0.1px;
  padding-top: 0.1px;
  background: #ffffff !important;
}
.ant-menu-inline-collapsed {
  width: 0px;
}
.div-sider-menu {
  margin-bottom: 8px;
}
.ant-layout-sider-children {
  /* height: 100%; */
  margin-top: 0px;
  padding-top: 0.1px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 25px !important;
  padding-top: 0px;
  text-overflow: clip;
  margin-right: 0px !important;
  margin-top: 0px;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: -7px !important;
}

.menu-item-style .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #e0e7f5;
}

.ant-menu-item-selected {
  color: #1d3557;
}

.ant-card {
  border-radius: 0px;
  box-shadow: 0px 0px 0px #00000033 !important;
}

.icon-style {
  text-align: center;
}

.div-side-menu.ant-divider-horizontal {
  margin: 1.8px 0px;
}

/* .ant-divider-horizontal {
  display: block;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  margin-bottom: 0px;
  margin-top: 1.8px;
} */

.item-style {
  padding-bottom: 10px;
}

.icon-logo {
  height: 28px;
  padding-left: 24px;
}

.icon-logo-d {
  height: 25px;
  margin: -7px;
}

.space-Icon {
  justify-content: space-between;
  display: flex;
  align-items: center;
  height: 46px;
}

.element-report {
  width: 169px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.leftMenu-sub-menu-lib-icon.ant-menu-submenu-vertical {
  margin-left: -7px;
}

.leftMenu-sub-menu-lib-icon.ant-menu-submenu-vertical .leftMenu-lib-icon {
  font-size: 15px;
}

.leftMenu-text-title {
  padding-left: 10px;
}

.image-icon-menu {
  margin-bottom: 7px;
}

.image-icon-menu-component:hover {
  margin-bottom: 7px;
}

.sub-menu-company .ant-menu-submenu-title:hover img {
  /* filter: invert(58%) sepia(58%) saturate(7149%) hue-rotate(4deg) brightness(106%) contrast(102%); */
}

.sub-menu-workforce .ant-menu-submenu-title:hover img {
  /* filter: invert(58%) sepia(58%) saturate(7149%) hue-rotate(4deg) brightness(106%) contrast(102%); */
}

.sub-menu-user-management .ant-menu-submenu-title:hover img {
  /* filter: invert(58%) sepia(58%) saturate(7149%) hue-rotate(4deg) brightness(106%) contrast(102%); */
}

.menu-item-style .ant-menu-submenu-selected img {
  /* filter: invert(58%) sepia(58%) saturate(7149%) hue-rotate(4deg) brightness(106%) contrast(102%); */
}
